<template>
  <div>
    <b-pagination size="sm" align="fill"
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  aria-controls="polizas-table"
    ></b-pagination>
    <!-- Table -->
    <b-table
      id="departamentoTable"
      v-if="getAvailableList"
      :fields="fields"
      :items="getResourceList"
      striped
      bordered
      responsive
      hover
      stacked="lg"
      show-empty
      small
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :filter-function="filterFunction"
      @filtered="onFiltered"
      empty-filtered-text="No se encontraron departamentos con el criterio de búsqueda escrito"
    >
      <!-- Eliminado -->
      <template #cell(is_deleted)="row">
        <b-badge v-if="row.item.is_deleted" variant="danger">No</b-badge>
        <b-badge v-else variant="light">Sí</b-badge>
      </template>

      <!-- # Catastral -->
      <template #cell(num_puerta)="row">
        <filtered-row :value="row.item.num_puerta" :filter="filter"/>
      </template>

      <template #cell(num_catastral)="row">
        <filtered-row :value="row.item.num_catastral" :filter="filter"/>
      </template>

      <!-- # Predial -->
      <template #cell(cuenta_predial)="row">
        <filtered-row :value="row.item.cuenta_predial" :filter="filter"/>
      </template>

      <template #cell(empresa.razon_social)="row">
        <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.empresa.razon_social }}
        </span>
      </template>

      <template #cell(condominio.nombre)="row">
        <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.condominio.nombre }}
        </span>
      </template>

      <!-- Tipo -->
      <template #cell(tipo.nombre)="row">
        <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.value }}
        </span>
      </template>

      <!-- Inicio contrato -->
      <template #cell(inicio_contrato)="row">
        <filtered-row :value="row.item.inicio_contrato" :filter="filter"/>
      </template>

      <!-- Término contrato -->
      <template #cell(termino_contrato)="row">
        <filtered-row :value="row.item.termino_contrato" :filter="filter"/>
      </template>

      <!-- Renta inicial -->
      <template #cell(renta_inicial)="row">
        <span class="text-sm text-right w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.renta_inicial }}
        </span>
      </template>

      <!-- Renta actual -->
      <template #cell(renta_actual)="row">
        <span class="text-sm text-right w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.renta_actual }}
        </span>
      </template>

      <!-- Descuento -->
      <template #cell(descuento)="row">
        <span class="text-sm text-right w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.descuento }}
        </span>
      </template>

      <!-- Amueblado -->
      <template #cell(amueblado)="row">
        <span class="text-sm text-right w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.amueblado == 1 ? "Si":"No" }}
        </span>
      </template>

      <!-- Forma de pago -->
      <template #cell(forma_pago.nombre)="row">
        <span class="text-sm text-right w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.value }}
        </span>
      </template>

      <!-- Inversionista -->
      <template #cell(inversionista.identificacion)="row">
        <filtered-row :value="row.item.inversionista.identificacion" :filter="filter"/>
      </template>

      <!-- Historial -->
      <template #cell(show_historial)="row">
        <b-button
          @click="row.toggleDetails"
          variant="light"
          class="btn-sm mb-2 btn-block btn-outline-black"
        >
          Historial
        </b-button>
      </template>

      <!-- Acciones -->
      <template #cell(acciones)="row">
        <div class="d-flex justify-content-center" v-if="!row.item.is_deleted">
          <u-d-controls
            :id="row.item.id"
            editPath="departamentoModule/getResourceToEdit"
            deletePath="departamentoModule/deleteResource"
            :editPermissions="['actualizar departamentos']"
            :deletePermissions="['eliminar departamentos']"
            @on-got-item="emitOnGotItem"
          ></u-d-controls>
        </div>
      </template>

      <template #row-details="row">
        <departamento-list-detail :row="row"></departamento-list-detail>
      </template>
    </b-table>
    <loading v-else></loading>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FilteredRow from '@/components/Common/Table/FilteredRow'
import DepartamentoListDetail from './DepartamentoListDetail.vue'

const STORE_MODULE = 'departamentoModule'

export default {
  name: 'DepartamentoList',

  components: {
    FilteredRow,
    DepartamentoListDetail
  },

  props: {
    filter: {
      required: true
    },
    search: {
      required: true
    }
  },

  data () {
    return {
      fields: [
        { key: 'num_puerta', label: 'Número puerta' },
        { key: 'num_catastral', label: 'Número catastral', sortable: true },
        { key: 'cuenta_predial', label: 'Cuenta Predial', sortable: true },
        { key: 'empresa.razon_social', label: 'Empresa' },
        { key: 'condominio.nombre', label: 'Proyecto' },
        { key: 'subcondominio.nombre', label: 'Etapa' },
        { key: 'edificio.nombre', label: 'Edificio' },
        { key: 'tipo.nombre', label: 'Tipo' },
        { key: 'inicio_contrato', label: 'Inicio contrato', sortable: true },
        { key: 'termino_contrato', label: 'Término contrato', sortable: true },
        { key: 'renta_inicial', label: 'Renta inicial' },
        { key: 'renta_actual', label: 'Renta actual' },
        { key: 'descuento', label: 'Descuento' },
        { key: 'amueblado', label: 'Amueblado' },
        { key: 'forma_pago.nombre', label: 'Forma de pago' },
        { key: 'inversionista.identificacion', label: 'Inversionista', sortable: true },
        { key: 'show_historial', label: 'Ver historial' },
        { key: 'acciones', label: 'Acciones' }
      ],

      perPage: 10,

      currentPage: 1,

      totalRows: 0,

      filterOn: ['num_catastral', 'cuenta_predial', 'inicio_contrato',
        'termino_contrato', 'inversionista_identificacion']
    }
  },

  computed: {
    ...mapGetters(STORE_MODULE, [
      'getResourceList',
      'getAvailableList',
      'getTotalRows',
      'isThereData',
      'getResource'
    ])
  },

  watch: {
    search: {
      immediate: true,
      handler (value) {
        this.getlist(value)
      }
    }
  },

  methods: {
    async getlist (value) {
      const { error, message } = await this.$store.dispatch(`${STORE_MODULE}/getResource`, value)
      this.totalRows = this.getTotalRows

      if (error) this.$notify({ error, message }, 'Inversionistas')
    },

    filterFunction (row, filter) {
      if (!filter || filter === '') {
        return false
      }
      return (row.num_puerta && row.num_puerta.toLowerCase().includes(filter.toLowerCase())) ||
        (row.num_catastral && row.num_catastral.toLowerCase().includes(filter.toLowerCase())) ||
        (row.cuenta_predial && row.cuenta_predial.toLowerCase().includes(filter.toLowerCase())) ||
        (row.inicio_contrato && row.inicio_contrato.toLowerCase().includes(filter.toLowerCase())) ||
        (row.termino_contrato && row.termino_contrato.toLowerCase().includes(filter.toLowerCase())) ||
        (row.inversionista && row.inversionista.identificacion.toLowerCase().includes(filter.toLowerCase()))
    },

    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    emitOnGotItem () {
      this.$emit('on-got-item')
    }
  }
}
</script>

<style scoped>

</style>
